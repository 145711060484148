/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { API } from "../api";
import useBetterMediaQuery from "../hooks/useBetterMediaQuery";
import { fasterFetch, getNextDateRepeatableEvent, placeholderUserUrl } from "../utils";
import imageCrossOriginer from "../utils/imageCrossOriginer";
import HoverFeedback from "./HoverFeedback";
import MLDialog from "./MLDialog";
import Stack from "./Stack";
import type { MerchantCollaborationType } from "./event/CollaboratorSection";
import NowrIcon from "./icons/NowrIcon";
export interface IImage {
	id?: string;
	url: string;
	main: boolean;
	mediaType: "PHOTO" | "VIDEO";
	// thumb_url: string;
	mediaUrl: string;
}

export interface IUser {
	id: number;
	admin?: boolean;
	fbId: string;
	isHost?: boolean;
	emailConfirmed: boolean;
	firstName: string;
	lastName: string;
	email: string;
	alias: string;
	username: string;
	pictureUrl: string;
	followed: number;
	follower: number;
	followIt: boolean;
	userState: string;
	bio: string;
	invoiceSubjectType: string;
	businessName: string;
	vatNumber: string;
	fiscalCode: string;
	pecAddress: string;
	sdiCode: string;
	invoiceBusinessType: string;
	invoiceFirstName: string;
	invoiceLastName: string;
	invoiceAddress: string;
	invoiceDistrict: string;
	invoiceCap: string;
	invoicePlace: string;
	iban: string;
	revenueSplit: number;
	merchantCollaborationType: MerchantCollaborationType;
	deactivatedPromoterStatus: boolean;
}

export interface ILocation {
	latitude: number;
	longitude: number;
	name: string;
	description: string;
	sourceId: string;
}

export interface IChannel {
	id: string;
	name: string;
	description: string;
	ur: string;
	publishedDate: string;
}

export interface IEventCard {
	id: string;
	mainPicture: IImage;
	name: string;
	startDate: string;
	endDate: string;
	publishedDate: string;
	location: ILocation;
	channels: IChannel[];
	owner: IUser;
	likesCount: number;
	free: boolean;
	status: "VALID" | "DRAFT" | "TERMINATED";
	checkinsCount: number;
	priceType: string;
	iLoveIt: boolean;
	refundPolicy: string;
	iCheckedIn: boolean;
	token?: string;
	messagesCount: number;
	inEvidence?: boolean;
	shareOnClick?: () => void;
}

const EventCard = (props: IEventCard) => {
	const theme = useTheme();

	const isMobile = useBetterMediaQuery("md", "down");

	const eventUrl = `/events/${props.id}`;
	const profileUrl = `/profile/${props.owner.alias}`;

	const [iLoveIt, setILoveIt] = useState(props.iLoveIt);
	const [iLoveItCount, setILoveItCount] = useState(props.likesCount);

	const router = useRouter();

	const nextOccurringDate = getNextDateRepeatableEvent(props as any);

	const cardWidth = isMobile ? 271 : 291;

	return (
		<div
			css={css`
				width: 100%;
				max-width: 376px;
				display: flex;
				position: relative;
				font-size: 16px;
				margin: 0 auto;
				margin-bottom: 72px;
			`}
		>
			<Link href="/events/[eventId]" as={eventUrl}>
				<a
					href={eventUrl}
					title={props.name}
					css={css`
						color: ${theme.palette.text.primary};
						text-decoration: none;
						width: 100%;
					`}
				>
					<div
						css={css`
							width: ${cardWidth}px;
							height: ${cardWidth}px;

							position: relative;
							border-radius: 24px;
							overflow: hidden;
							:after {
								content: "''";
								top: 0;
								left: 0;
								position: absolute;
								width: ${cardWidth}px;
								height: ${cardWidth}px;
								z-index: 1;
								background: linear-gradient(
									179.93deg,
									rgba(0, 0, 0, 0) 67.85%,
									rgba(0, 0, 0, 0.1924) 79.63%,
									rgba(0, 0, 0, 0.74) 99.94%
								);
							}

							z-index: 5;
							&:hover {
								cursor: pointer;
							}
						`}
					>
						{props?.mainPicture?.mediaType === "VIDEO" ? (
							<video
								crossOrigin=""
								autoPlay
								poster={imageCrossOriginer(props?.mainPicture?.url)}
								muted
								loop
								css={css`
									height: ${cardWidth}px;
									width: ${cardWidth}px;
									background: black;

									object-fit: cover;
								`}
								src={imageCrossOriginer(props?.mainPicture?.mediaUrl ?? props.mainPicture.url)}
							/>
						) : (
							<img
								alt=""
								crossOrigin=""
								css={css`
									height: ${cardWidth}px;
									width: ${cardWidth}px;
									background: #f2f2f2;
									object-fit: cover;
								`}
								src={imageCrossOriginer(props?.mainPicture?.url)}
							/>
						)}
						<Typography
							children={props?.name || ""}
							css={{
								position: "absolute",
								color: "#F8FFFD",
								zIndex: 2,

								bottom: 24,
								left: 24,
								right: 24,
								fontSize: 24,
								fontWeight: "bold",
							}}
						/>
					</div>
				</a>
			</Link>
			<div
				css={css`
					height: calc(100%);
					width: ${cardWidth}px;
					position: absolute;
					left: 54px;
					top: 54px;
					border-radius: 24px;
					overflow: hidden;
					z-index: 0;
					background-color: ${theme.palette.primary.main};
					justify-content: flex-end;
					display: flex;
					flex-direction: column;
				`}
			>
				<Link href="/profile/[alias]" as={profileUrl}>
					<a
						href={profileUrl}
						css={css`
							height: 100%;
							text-decoration: none;
						`}
					>
						<Grid container justifyContent="flex-end" css={{ height: "100%", color: theme.palette.text.primary }}>
							<Grid item css={{ height: "100%" }}>
								<Grid
									container
									direction="column"
									alignItems="center"
									justifyContent="flex-start"
									alignContent="center"
									css={{
										padding: 8,

										height: "100%",

										borderRadius: 24,
										":hover": {
											backgroundColor: "rgba(12, 25, 23, 0.12)",
										},
										":active": {
											backgroundColor: "rgba(12, 25, 23, 0.38)",
										},
									}}
								>
									<Grid item>
										<img
											alt=""
											crossOrigin=""
											css={css`
												border-radius: 20px;
												object-fit: cover;
											`}
											width={36}
											height={36}
											onError={(e) => {
												e.currentTarget.setAttribute("src", placeholderUserUrl);
											}}
											src={imageCrossOriginer(props?.owner?.pictureUrl)}
										/>
									</Grid>
									<Grid item css={{ position: "relative" }}>
										<div
											css={css`
												position: absolute;
												font-weight: bold;
												transform: rotate(-180deg);
												left: -10px;
												top: 8px;
												writing-mode: vertical-rl;
												text-orientation: sideways;
												/* background: blue; */
											`}
										>
											{props?.owner?.alias ?? ""}
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</a>
				</Link>

				<Stack css={{ padding: 8 }} justifyContent="space-between">
					<Stack>
						<NowrIcon name="clock_outline" />
						<Typography css={{ marginLeft: 12 }} children={moment(nextOccurringDate).format("DD MMM. - HH:mm")} />
					</Stack>

					<Stack>
						<HoverFeedback
							radius={20}
							hoverColor="rgba(12, 25, 23, 0.12)"
							activeColor="rgba(12, 25, 23, 0.38)"
							onClick={props.shareOnClick}
						>
							<NowrIcon name="share" />
						</HoverFeedback>
						<HoverFeedback
							radius={20}
							hoverColor="rgba(12, 25, 23, 0.12)"
							activeColor="rgba(12, 25, 23, 0.38)"
							onClick={() => {
								if (props.token) {
									//! like
									fasterFetch(iLoveIt ? API.like.remove : API.like.create, {
										method: "POST",
										headers: { "X-Auth-Token": props.token, "Content-Type": "application/json" },
										body: JSON.stringify({ event: { id: props.id } }),
									}).catch((error) => console.log("error", error));

									setILoveItCount(iLoveIt ? iLoveItCount - 1 : iLoveItCount + 1);
									setILoveIt(!iLoveIt);
								} else
									MLDialog.showModal("Attenzione", "Accesso richiesto", "Accedi per poter mettere mi piace", {
										negativeText: "Annulla",
										positiveText: "Accedi",
										onNegativeClick: () => {},
										onPositiveClick: () => {
											router.push("/access/login");
										},
									});
							}}
						>
							<NowrIcon name={iLoveIt ? "heart_bold" : "heart_outline"} />
						</HoverFeedback>
					</Stack>
				</Stack>
			</div>
		</div>
	);
};

export default EventCard;
